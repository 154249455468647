export default {
  "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si"])},
  "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
  "login": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benvenuti nel portale Ecogy.app"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceda con email e password"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "password2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ripeti password"])},
    "savepassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva Password"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accedi"])},
    "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ho dimenticato la password"])},
    "resentPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manda la password"])},
    "backlogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Torna al login"])},
    "resettitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resetta la password"])},
    "resetlinktitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registra nuova Password"])},
    "resetdescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci la nuova password per ecogy.app"])}
  },
  "home": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ecogy.app Hotel Portal"])},
    "rfidcards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schede RFID"])},
    "transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transazioni"])},
    "stations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stazioni di ricarica"])},
    "prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestione prezzo"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestione utenti"])},
    "tenants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestione tenant"])},
    "tenantchanged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il tenant attivo é stato sostituito"])},
    "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vai"])},
    "go": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go"])}
  },
  "transactions": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transazioni"])},
    "forceUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forzare aggiornamentod al Backend"])},
    "updateDone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I dati sono stati aggiornamti"])},
    "updateError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore nel aggiornamento dei dati"])},
    "table": {
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Id"])},
      "cb_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stazione di ricarica"])},
      "card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scheda RFID"])},
      "cardName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RFID Nome"])},
      "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inizio"])},
      "end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fine"])},
      "energyWh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consumo kWh"])},
      "stopReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motivo termine"])},
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prezzo"])},
      "isWebhook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PMS Auth."])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "statuses": {
        "INIZIALIZING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inizializazione"])},
        "SUSPENDED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sospeso"])},
        "NO_INVOICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non da fatturare"])},
        "CLOSED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiuso"])},
        "PREINVOICED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prima di fatturare"])},
        "ERRORINVOICED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore in fatturazione"])},
        "INVOICED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fatturato"])},
        "FINISHED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finito"])},
        "RUNNING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attivo"])}
      }
    },
    "filter": {
      "transactiondate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periodo"])},
      "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a"])},
      "startdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inizio"])},
      "enddate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fine"])},
      "card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scheda RFID"])},
      "station": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stazione di ricarica"])},
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti"])},
      "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risultati"])}
    }
  },
  "users": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ecogy.app User Management"])},
    "table": {
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
      "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ruolo"])},
      "defaulttenant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tenant di default"])},
      "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abilitato"])},
      "addnew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuovo utente"])},
      "resetPwd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invia reset Password"])}
    },
    "edit": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestisci Utente"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
      "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ruolo"])},
      "defaulttenant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tenant di default"])},
      "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abilitato"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva"])}
    }
  },
  "tenants": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestione Tenant"])},
    "table": {
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tenant Id"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
      "chargingBackend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backend di carica"])},
      "pmsBackend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PMS Backend"])}
    },
    "edit": {
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tenant Id"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome Sito"])},
      "logoUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo Url"])},
      "loginTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titolo Login"])},
      "backgroundColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colore Background"])},
      "loginDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrizione Login"])},
      "chargingBackend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backend di carica"])},
      "chargingBackendId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backend ID"])},
      "chargingBackendUsername": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backend Username"])},
      "chargingBackendPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backend Password"])},
      "chargingBackendClientId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backend Client Id"])},
      "chargingBackendClientSecret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backend Secret"])},
      "pmsBackend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PMS Backend"])},
      "pmsBackendHotelCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel Code"])},
      "pmsChargeId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charge Id"])},
      "pmsPreAuthChargeId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pre. Auth. Charge Id"])},
      "pmsPreAuthQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pre Auth Quantity in kWh (0 = nessun Pre Auth)"])},
      "pmsPreAuthQuantityHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La quantita in kWh che viene addebitata prima della ricarica nel PMS Backend. Se il valore é 0, nessun pre autorizzazione é eseguita"])},
      "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abilitato"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica Tenant"])},
      "PmsBackendUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" PMS Backend Url"])},
      "PmsUsername": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" PMS Username"])},
      "PmsPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" PMS Password"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva"])},
      "pmsRfidCardAuthorisationEnabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authorizzazione RFID dal Backend PMS?"])},
      "pmsRfidCardAuthorisationTransformationFunction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RFID Funzione di transformazione"])},
      "pmsSendTotalPriceToPms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invia prezzo al PMS"])},
      "pmsSendTotalPriceToPmsFalse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invia solo quantita, nessun prezzo (Prezzo é gestito nel PMS)"])},
      "pmsSendTotalPriceToPmsTrue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invia quantita e prezzo (Vale il prezzo di ecogy)"])},
      "isEichkonform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le stationi di ricarica sono Eichkonform"])}
    }
  },
  "rfidCards": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestione Schede RFID"])},
    "table": {
      "idTag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rfid Tag"])},
      "inTransaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["in Transazione?"])},
      "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abilitato"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
      "addnew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuova Scheda"])},
      "isWebhook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PMS Card"])}
    }
  },
  "loadingStation": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stazione di carica"])},
    "table": {
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Id"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
      "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avvia"])},
      "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resetta"])},
      "stop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interrompi"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato"])},
      "online": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online"])},
      "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea Stazione nuova"])}
    },
    "confirmTenant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La Stazione di ricarica viene creata in questo Tenant - Il Tenant non puo essere cambiato successivamente."])},
    "confirmReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confermi che vuoi eseguire un HARD Reset sulla stazione di carica?"])},
    "confirmDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confermi che vuoi stoppare l'attuale processo di ricarica?"])},
    "startTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inizio ricarica"])}
  },
  "prices": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestione Price"])},
    "table": {
      "validFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valido dal"])},
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prezzo per kWh"])},
      "addnew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuovo Price"])}
    }
  }
}