import "core-js/modules/es.array.push.js";
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_divider = _resolveComponent("el-divider");
  const _component_CreditCard = _resolveComponent("CreditCard");
  const _component_el_icon = _resolveComponent("el-icon");
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_card = _resolveComponent("el-card");
  const _component_el_col = _resolveComponent("el-col");
  const _component_Odometer = _resolveComponent("Odometer");
  const _component_AddLocation = _resolveComponent("AddLocation");
  const _component_PriceTag = _resolveComponent("PriceTag");
  const _component_el_row = _resolveComponent("el-row");
  const _component_User = _resolveComponent("User");
  const _component_House = _resolveComponent("House");
  const _component_Setting = _resolveComponent("Setting");
  const _component_el_option = _resolveComponent("el-option");
  const _component_el_select = _resolveComponent("el-select");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("h2", null, _toDisplayString(_ctx.$t("home.title")), 1), _createVNode(_component_el_divider), _createVNode(_component_el_row, {
    gutter: 0
  }, {
    default: _withCtx(() => [_createVNode(_component_el_col, {
      md: 4
    }, {
      default: _withCtx(() => [_createVNode(_component_el_card, {
        "body-style": {
          padding: '0px'
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_el_icon, {
          size: 150,
          class: "menuicon"
        }, {
          default: _withCtx(() => [_createVNode(_component_CreditCard)]),
          _: 1
        }), _cache[8] || (_cache[8] = _createElementVNode("br", null, null, -1)), _createVNode(_component_el_button, {
          type: "primary",
          onClick: _cache[0] || (_cache[0] = $event => _ctx.$router.push('rfidcards'))
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t("home.rfidcards")), 1)]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_el_col, {
      md: {
        span: 4,
        offset: 3
      }
    }, {
      default: _withCtx(() => [_createVNode(_component_el_card, {
        "body-style": {
          padding: '0px'
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_el_icon, {
          size: 150,
          class: "menuicon"
        }, {
          default: _withCtx(() => [_createVNode(_component_Odometer)]),
          _: 1
        }), _cache[9] || (_cache[9] = _createElementVNode("br", null, null, -1)), _createVNode(_component_el_button, {
          type: "primary",
          onClick: _cache[1] || (_cache[1] = $event => _ctx.$router.push('transactions'))
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t("home.transactions")), 1)]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_el_col, {
      md: {
        span: 4,
        offset: 3
      }
    }, {
      default: _withCtx(() => [_createVNode(_component_el_card, {
        "body-style": {
          padding: '0px'
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_el_icon, {
          size: 150,
          class: "menuicon"
        }, {
          default: _withCtx(() => [_createVNode(_component_AddLocation)]),
          _: 1
        }), _cache[10] || (_cache[10] = _createElementVNode("br", null, null, -1)), _createVNode(_component_el_button, {
          type: "primary",
          onClick: _cache[2] || (_cache[2] = $event => _ctx.$router.push('loadingstations'))
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t("home.stations")), 1)]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_el_col, {
      md: {
        span: 4,
        offset: 2
      }
    }, {
      default: _withCtx(() => [_createVNode(_component_el_card, {
        "body-style": {
          padding: '0px'
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_el_icon, {
          size: 150,
          class: "menuicon"
        }, {
          default: _withCtx(() => [_createVNode(_component_PriceTag)]),
          _: 1
        }), _cache[11] || (_cache[11] = _createElementVNode("br", null, null, -1)), _createVNode(_component_el_button, {
          type: "primary",
          onClick: _cache[3] || (_cache[3] = $event => _ctx.$router.push('prices'))
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t("home.prices")), 1)]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  }), _createVNode(_component_el_divider), _ctx.isAdmin() ? (_openBlock(), _createBlock(_component_el_row, {
    key: 0,
    gutter: 0
  }, {
    default: _withCtx(() => [_createVNode(_component_el_col, {
      md: {
        span: 4,
        offset: 3
      }
    }, {
      default: _withCtx(() => [_createVNode(_component_el_card, {
        "body-style": {
          padding: '0px'
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_el_icon, {
          size: 150,
          class: "menuicon"
        }, {
          default: _withCtx(() => [_createVNode(_component_User)]),
          _: 1
        }), _cache[12] || (_cache[12] = _createElementVNode("br", null, null, -1)), _createVNode(_component_el_button, {
          type: "primary",
          onClick: _cache[4] || (_cache[4] = $event => _ctx.$router.push('users'))
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t("home.users")), 1)]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_el_col, {
      md: {
        span: 4,
        offset: 3
      }
    }, {
      default: _withCtx(() => [_createVNode(_component_el_card, {
        "body-style": {
          padding: '0px'
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_el_icon, {
          size: 150,
          class: "menuicon"
        }, {
          default: _withCtx(() => [_createVNode(_component_House)]),
          _: 1
        }), _cache[13] || (_cache[13] = _createElementVNode("br", null, null, -1)), _createVNode(_component_el_button, {
          type: "primary",
          onClick: _cache[5] || (_cache[5] = $event => _ctx.$router.push('tenants'))
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t("home.tenants")), 1)]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_el_col, {
      md: {
        span: 4,
        offset: 3
      }
    }, {
      default: _withCtx(() => [_createVNode(_component_el_card, {
        "body-style": {
          padding: '0px'
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_el_icon, {
          size: 150,
          class: "menuicon"
        }, {
          default: _withCtx(() => [_createVNode(_component_Setting)]),
          _: 1
        }), _cache[14] || (_cache[14] = _createElementVNode("br", null, null, -1)), _createVNode(_component_el_select, {
          modelValue: _ctx.selectedTenant,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => _ctx.selectedTenant = $event),
          class: "m-2",
          filterable: "",
          placeholder: "Select tenant to work with",
          size: "large"
        }, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tenants, item => {
            return _openBlock(), _createBlock(_component_el_option, {
              key: item.id,
              label: item.name,
              value: item.id
            }, null, 8, ["label", "value"]);
          }), 128))]),
          _: 1
        }, 8, ["modelValue"]), _createVNode(_component_el_button, {
          type: "primary",
          onClick: _cache[7] || (_cache[7] = $event => _ctx.changeTenant())
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t("home.go")), 1)]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  })) : _createCommentVNode("", true)]);
}