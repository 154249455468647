export default {
  "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])},
  "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nein"])},
  "login": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Willkommen im Ecogy.app portal"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Melde dich mit E-Mail und Password an"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "password2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password wiederholen"])},
    "savepassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password Speichern"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden"])},
    "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["habe mein Password vergessen"])},
    "resentPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password senden"])},
    "backlogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück zu login"])},
    "resettitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password zurücksetzen"])},
    "resetlinktitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Password registrieren"])},
    "resetdescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrieren Sie ihr neues Kennwort für ecogy.app"])}
  },
  "home": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ecogy.app Hotel Portal"])},
    "rfidcards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RFID Karten"])},
    "transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaktionen"])},
    "stations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ladestationen"])},
    "prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preise verwalten"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer verwalten"])},
    "tenants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tenant verwalten"])},
    "tenantchanged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der aktive Tenant wurde gewechselt"])},
    "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go"])},
    "go": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go"])}
  },
  "transactions": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaktionen"])},
    "forceUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualisierung vom Backend erzwingen"])},
    "updateDone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daten wurden aktualisiert"])},
    "updateError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler im aktualisieren der Daten"])},
    "table": {
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Id"])},
      "cb_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ladestation"])},
      "card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RFID Karte"])},
      "cardName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RFID Name"])},
      "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beginn"])},
      "end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ende"])},
      "energyWh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbrauchte kWh"])},
      "stopReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbruchgrund"])},
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preis"])},
      "isWebhook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PMS Auth."])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "statuses": {
        "INIZIALIZING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inizialisierung"])},
        "SUSPENDED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angehalten"])},
        "NO_INVOICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht abzurechnen"])},
        "CLOSED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgeschlossen"])},
        "PREINVOICED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vor Abrechnung"])},
        "ERRORINVOICED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler in Abrechnung"])},
        "INVOICED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgerechnet"])},
        "FINISHED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fertig"])},
        "RUNNING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])}
      }
    },
    "filter": {
      "transactiondate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeitraum"])},
      "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bis"])},
      "startdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beginn"])},
      "enddate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ende"])},
      "card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RFID Karte"])},
      "station": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ladestation"])},
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle"])},
      "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultate"])}
    }
  },
  "users": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ecogy.app Benutzer Verwaltung"])},
    "table": {
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rolle"])},
      "defaulttenant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Defaulttenant"])},
      "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freigeschalten"])},
      "addnew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuer Benutzer"])},
      "resetPwd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sende Password reset"])}
    },
    "edit": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer bearbeiten"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rolle"])},
      "defaulttenant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Defaulttenant"])},
      "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freigeschalten"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])}
    }
  },
  "tenants": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tenant Verwalten"])},
    "table": {
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tenant Id"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "chargingBackend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LadeBackend"])},
      "pmsBackend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PMS Backend"])}
    },
    "edit": {
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tenant Id"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitenamen"])},
      "logoUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo Url"])},
      "loginTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titel Login"])},
      "backgroundColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hintergrundfarbe"])},
      "loginDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loginbeschreibung"])},
      "chargingBackend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ladebackend"])},
      "chargingBackendId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backend ID"])},
      "chargingBackendUsername": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backend Username"])},
      "chargingBackendPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backend Password"])},
      "chargingBackendClientId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backend Client Id"])},
      "chargingBackendClientSecret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backend Secret"])},
      "pmsBackend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PMS Backend"])},
      "pmsBackendHotelCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel Code"])},
      "pmsChargeId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charge Id"])},
      "pmsPreAuthChargeId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pre. Auth. Charge Id"])},
      "pmsPreAuthQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pre Auth Menge"])},
      "pmsPreAuthQuantityHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menge in kWh die vor der Ladung reserviert wird; 0 = keine Pre Auth"])},
      "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freigeschalten"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tenant bearbeiten"])},
      "PmsBackendUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" PMS Backend Url"])},
      "PmsUsername": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" PMS Username"])},
      "PmsPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" PMS Password"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
      "pmsRfidCardAuthorisationEnabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RFID authorisierung von PMS Backend?"])},
      "pmsRfidCardAuthorisationTransformationFunction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RFID Transformationsfunktion"])},
      "pmsSendTotalPriceToPms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sende Price zum PMS"])},
      "pmsSendTotalPriceToPmsFalse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sende nur Menge keine Preis (Preis wird im PMS Verwaltet)"])},
      "pmsSendTotalPriceToPmsTrue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sende Menge und Preis (Ecogy Price zählt)"])},
      "isEichkonform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Ladestationen sind Eichkonform?"])}
    }
  },
  "rfidCards": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RFID Karten verwalten"])},
    "table": {
      "idTag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RFID Tag"])},
      "inTransaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aktuell benutzt?"])},
      "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freigeschalten"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "addnew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Karte"])},
      "isWebhook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PMS Card"])}
    }
  },
  "loadingStation": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ladestationen"])},
    "table": {
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Id"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starten"])},
      "stop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stoppen"])},
      "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resetten"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "online": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online"])},
      "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Station erstellen"])}
    },
    "confirmTenant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Ladestation wird in diesem Tenant erstellt - Der Tenant kann nachträglich NICHT geöndert werden."])},
    "confirmReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sicher dass Sie auf der Ladestation ein Hard Reset durchführen wollen?"])},
    "confirmDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sicher dass Sie die Ladung stoppen wollen?"])},
    "startTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startzeitpunkt"])}
  },
  "prices": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preisverwaltung"])},
    "table": {
      "validFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gültig ab"])},
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preis pro kWh"])},
      "addnew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuer Preis"])}
    }
  }
}