import axios from "axios";
import config from '../config';
import VueJwtDecode from 'vue-jwt-decode';

export const makeLogin = async (email: string, password: string) => {
    const g = await axios.post<string>(config.LoginUrl, {
      UserName: email.trim(), 
      password: password, 
      TenantId: '',
      enabled: true
    });
    saveLogin(g.data);
  }

export const saveLogin = async(token: string) => {
    sessionStorage.setItem("user", JSON.stringify(token));
    try{
      const decoded = VueJwtDecode.decode(token);
      sessionStorage.setItem("tenant", decoded.tenant);
      sessionStorage.setItem("role", decoded.role);
      sessionStorage.setItem("roleAdmin", decoded["Role-Administrator"]);
    } catch (e) {
      // eslint-disable-next-line
      console.log("error in decode:", e);
    }
    return token;
}