import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import _imports_0 from '@/assets/logo.jpg';
import _imports_1 from '@/assets/logo.png';
const _hoisted_1 = {
  class: "lang"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_link = _resolveComponent("router-link");
  const _component_el_radio_button = _resolveComponent("el-radio-button");
  const _component_el_radio_group = _resolveComponent("el-radio-group");
  const _component_el_header = _resolveComponent("el-header");
  const _component_router_view = _resolveComponent("router-view");
  const _component_el_main = _resolveComponent("el-main");
  const _component_el_footer = _resolveComponent("el-footer");
  const _component_el_container = _resolveComponent("el-container");
  return _openBlock(), _createBlock(_component_el_container, {
    style: {
      "--el-color-primary": "#166a2d",
      "--el-color-primary-light-3": "#547b5b",
      "--el-color-primary-light-5": "#547b5b"
    }
  }, {
    default: _withCtx(() => [_createVNode(_component_el_header, null, {
      default: _withCtx(() => [_createVNode(_component_router_link, {
        to: "/"
      }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [_createElementVNode("img", {
          src: _imports_0,
          class: "logo"
        }, null, -1)])),
        _: 1
      }), _createElementVNode("div", _hoisted_1, [_createVNode(_component_el_radio_group, {
        modelValue: _ctx.language,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.language = $event),
        size: "small",
        onChange: _ctx.langchange
      }, {
        default: _withCtx(() => [_createVNode(_component_el_radio_button, {
          label: "de"
        }, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [_createTextVNode("DE")])),
          _: 1
        }), _createVNode(_component_el_radio_button, {
          label: "it"
        }, {
          default: _withCtx(() => _cache[3] || (_cache[3] = [_createTextVNode("IT")])),
          _: 1
        }), _createVNode(_component_el_radio_button, {
          label: "en"
        }, {
          default: _withCtx(() => _cache[4] || (_cache[4] = [_createTextVNode("EN")])),
          _: 1
        })]),
        _: 1
      }, 8, ["modelValue", "onChange"])])]),
      _: 1
    }), _createVNode(_component_el_main, null, {
      default: _withCtx(() => [_createVNode(_component_router_view)]),
      _: 1
    }), _createVNode(_component_el_footer, null, {
      default: _withCtx(() => _cache[5] || (_cache[5] = [_createElementVNode("a", {
        href: "https://ecogy.it",
        target: "_blank"
      }, [_createElementVNode("img", {
        src: _imports_1
      })], -1)])),
      _: 1
    })]),
    _: 1
  });
}